import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthStore } from '../../utils/context/authStore';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';
import { trackUserInteraction } from '../../utils/helpers';
import { useFeatureFlagEnabled } from 'posthog-js/react';

const Sidebar = () => {
    const router = useHistory();
    const { account, logOut, globalFlags } = AuthStore();
    const [open, setOpen] = React.useState(false);
    const [clickedOn, setClickedOn] = React.useState({});
    const [menuToggled, setMenuToggled] = useState(false);

    const isEditingSession = window.location.href.includes('/gdpr-essentials/edit');

    const hasDocumentsFlag = useFeatureFlagEnabled('documents');
    const settingsFlag = useFeatureFlagEnabled('sidenav-settings');

    const handleClose = () => {
        setOpen(false);
    };

    const handleDiscard = () => {
        setOpen(false);
        if (clickedOn.label === 'logo') return router.push('/');
        if (clickedOn.label === 'logOut') return confirmLogOut();
        if (clickedOn.label === 'navigationMenu') return onMenuClicked(clickedOn.entry, true);
    };

    const confirmLogOut = async () => {
        dismissAlert();
        // Deletes cookie
        logOut();
    };

    const handleClickLogo = async () => {
        if (isEditingSession) {
            // Open Dialog to check if user wants to lose changes
            setOpen(true);
            setClickedOn({ label: 'logo' });
            return false;
        }
        router.push('/');
    };
    const prod = process.env.NODE_ENV === 'production';

    const handleClickLogOut = async () => {
        if (isEditingSession) {
            // Open Dialog to check if user wants to lose changes
            setOpen(true);
            setClickedOn({ label: 'logOut' });
            return false;
        }
        logOut();
    };

    const isEditConfirmationDialog = () => (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Unsaved Changes</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are your sure you want to discard these changes? Your data inventory will not be updated if you leave without reaching
                    the end of the flow.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleClose}>
                    Continue Editing
                </Button>
                <Button variant="contained" onClick={handleDiscard}>
                    Discard
                </Button>
            </DialogActions>
        </Dialog>
    );

    const getMenuEntries = () => {
        let menuEntries = [];

        if (isLicensedAndGelFinished) {
            menuEntries = [
                {
                    label: `Privacy Portal`,
                    icon: `fa-light fa-shield-check`,
                    link: `/settings/privacy-portal`,
                },

                {
                    label: `Cookie Banner`,
                    icon: `fa-regular fa-cookie`,
                    link: `/cookie-banner`,
                },

                {
                    label: `Shareables`,
                    icon: `fa-regular fa-arrow-up-from-bracket`,
                    link: `/shareables`,
                },
            ];

            if (globalFlags.dashboardOnboardingEntities) {
                menuEntries.unshift({
                    label: `Dashboard`,
                    icon: `fa-light fa-grid-2`,
                    onClick: () => (window.location.href = prod ? 'https://beta.privasee.io/' : 'http://localhost:3001/'),
                });
            } else {
                menuEntries.unshift({
                    label: `Dashboard`,
                    icon: `fa-light fa-grid-2`,
                    link: `/`,
                });
            }

            // Data Inventory / Tools and Third Parties flag.
            // thirdPartiesBeta needs to be removed upon modules cleanup.
            if (globalFlags.dashboardOnboardingEntities) {
                menuEntries.push({
                    label: `Tools and Third Parties`,
                    icon: `fa-regular fa-database`,
                    onClick: () =>
                        (window.location.href = prod ? 'https://beta.privasee.io/third-parties' : 'http://localhost:3001/third-parties'),
                });
            } else {
                menuEntries.push({
                    label: `Data Inventory`,
                    icon: `fa-regular fa-database`,
                    link: `/data-inventory`,
                });
            }

            if (globalFlags.dashboardOnboardingEntities) {
                menuEntries.push({
                    label: `Processes`,
                    icon: `fa-light fa-list-tree`,
                    onClick: () => (window.location.href = prod ? 'https://beta.privasee.io/processes' : 'http://localhost:3001/processes'),
                });
                // this has to be removed once we kill the old modules
            }
            if (globalFlags.dashboardOnboardingEntities) {
                menuEntries.push({
                    label: `Individuals`,
                    icon: `fa-light fa-users`,
                    onClick: () =>
                        (window.location.href = prod ? 'https://beta.privasee.io/individuals' : 'http://localhost:3001/individuals'),
                });
                // this has to be removed once we kill the old modules
            }

            if (globalFlags.audits) {
                menuEntries.push({
                    label: `Audits`,
                    icon: `fa-regular fa-file-magnifying-glass`,
                    link: `/audits`,
                });
            }

            if (globalFlags['virtual-dpo']) {
                menuEntries.push({
                    label: `Virtual DPO`,
                    icon: `fa-regular fa-message-bot`,
                    link: `/ai/virtual-dpo`,
                });
            }

            if (globalFlags['qaModule']) {
                menuEntries.push({
                    label: `Questions & Answers`,
                    icon: `fa-regular fa-block-question`,
                    link: `/questions-and-answers`,
                });
            }
        }

        // The only ones who should see workspace is people with gel finished or at least people with more than 1 companies in workspace.
        const shouldSeeWorkspace = isLicensedAndGelFinished ? true : account.workspace._companyIds.length > 1 ? true : false;

        if (shouldSeeWorkspace) {
            menuEntries.push({
                label: `Workspaces`,
                icon: `fal fa-door-open`,
                onClick: () => onMenuClicked({ label: 'Workspaces (v2)', link: '/workspaces' }),
                isBottomOnDesktop: true,
            });
        }

        if (isLicensedAndGelFinished) {
            menuEntries.push({
                label: `Settings`,
                icon: `fa-light fa-gear`,
                onClick: () =>
                    settingsFlag
                        ? (window.location.href = prod
                              ? 'https://beta.privasee.io/settings/company/general'
                              : 'http://localhost:3001/settings/company/general')
                        : onMenuClicked({ label: 'Settings', link: '/settings' }),

                isBottomOnDesktop: true,
            });
        }

        // We check their accesses now
        const dpaAccess = false;
        const securityMeasureAccesses = false;
        // Old code before we deprecated this functionality.
        // const dpaAccess = checkUserAccess({ flag: 'dpa', packageFeature: 'dpa' });
        // const securityMeasureAccesses = checkUserAccess({ flag: 'securityMeasures', packageFeature: 'securityMeasures' });

        if (dpaAccess && isLicensedAndGelFinished) {
            menuEntries.push({
                label: `DPAs`,
                icon: `fa-solid fa-calendar-lines-pen`,
                link: '/data-processing-agreements',
                isBottomOnDesktop: false,
            });
        }

        if (securityMeasureAccesses && isLicensedAndGelFinished) {
            menuEntries.push({
                label: `Security Measures`,
                icon: `fa-solid fa-shield-halved`,
                link: '/security-measures',
                isBottomOnDesktop: false,
            });
        }

        if (isLicensedAndGelFinished) {
            menuEntries.push({
                label: `Deals`,
                icon: `fa-solid fa-badge-dollar`,
                onClick: () => (window.location.href = prod ? 'https://beta.privasee.io/deals' : 'http://localhost:3001/deals'),
                isBottomOnDesktop: false,
            });

            menuEntries.push({
                label: `Knowledge Base`,
                icon: `fa-solid fa-lightbulb`,
                onClick: () =>
                    (window.location.href = prod ? 'https://beta.privasee.io/knowledge-base' : 'http://localhost:3001/knowledge-base'),
                isBottomOnDesktop: false,
            });
        }

        if (isLicensedAndGelFinished) {
            if (globalFlags.requirements) {
                menuEntries.push({
                    label: `Requirements (Beta)`,
                    icon: `fa-light fa-list-check`,
                    onClick: () =>
                        (window.location.href = prod ? 'https://beta.privasee.io/requirements' : 'http://localhost:3001/requirements'),
                });
            }

            if (globalFlags.aiAssistant) {
                menuEntries.push({
                    label: `AI Assistant (Beta)`,
                    icon: `fa-light fa-robot`,
                    onClick: () => (window.location.href = prod ? 'https://beta.privasee.io/assistant' : 'http://localhost:3001/assistant'),
                });
            }

            if (hasDocumentsFlag) {
                menuEntries.push({
                    label: `Documents`,
                    icon: `fa-light fa-file`,
                    onClick: () => (window.location.href = prod ? 'https://beta.privasee.io/documents' : 'http://localhost:3001/documents'),
                });
            }

            if (globalFlags.aiAssistantScaleable) {
                menuEntries.push({
                    label: `AI Assistant V2`,
                    icon: `fa-light fa-robot`,
                    onClick: () =>
                        (window.location.href = prod ? 'https://beta.privasee.io/assistantv2' : 'http://localhost:3001/assistantv2'),
                });
            }
        }

        menuEntries.push({
            label: `Log out`,
            onClick: () => handleClickLogOut(),
            icon: ` fa-light fa-arrow-right-from-bracket`,
            isBottomOnDesktop: true,
        });

        return menuEntries;
    };

    const onMenuClicked = async (entry, skipCheck = false) => {
        if (isEditingSession && skipCheck === false) {
            setOpen(true);
            setClickedOn({ label: `navigationMenu`, entry: entry });
            return false;
        }
        await trackUserInteraction(`Clicked on Navigation Menu`, { label: entry.label });
        router.push(entry.link);
    };

    const isLicensedAndGelFinished = account.hasCompletedGDPREssentials && account.workspace.licensed;

    const isEntrySelected = (entry) => {
        const location = window.location.pathname;
        const isHome = entry.label === 'Dashboard';

        if (isHome && location === '/') return true;
        else if (entry.label === 'Workspace' && location === '/workspaces') return true;
        else if (
            entry.label === 'Settings' &&
            location.includes('/settings') &&
            !location.includes(`/privacy-portal/`) &&
            !location.includes(`/cookie-banner/`)
        )
            return true;
        else if (!isHome && location.includes(`/onboarding`)) return false;
        else if (!isHome && entry.link && location.includes(entry.link)) {
            if (entry.link === '/security-measures' && location.includes('/data-processing-agreements')) return false;
            return true;
        }

        return false;
    };

    return (
        <React.Fragment>
            <div className="layout-nav-spacer layout-nav-width-fixer"></div>
            <div className="layout-navigation layout-nav-width-fixer">
                {isEditConfirmationDialog()}
                <div className="logo" onClick={handleClickLogo} />
                <div className="menu">
                    <div className="menu-toggler" key={`toggler-${menuToggled}`} onClick={() => setMenuToggled(!menuToggled)}>
                        <i className={`icon ${menuToggled == true ? `fa-light fa-xmark` : `fa-solid fa-bars`}`}></i>
                    </div>
                    <div className={`entries ${menuToggled === false && `disabled`}`}>
                        {getMenuEntries().map((entry, ix) => (
                            <div
                                key={ix}
                                className={`navigation-entry entry ${entry.isBottomOnDesktop === true && 'bottom'} ${
                                    isEntrySelected(entry) && 'selected'
                                }`}
                                onClick={entry.onClick ? entry.onClick : () => onMenuClicked(entry)}
                            >
                                <i className={`icon ${entry.icon}`}></i>
                                <div className="label">{entry.label}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="bottom-menu">
                    <div className={`entries`}>
                        {getMenuEntries().map((entry, ix) => (
                            <div
                                key={ix}
                                className={`navigation-entry entry ${!entry.isBottomOnDesktop && 'non-bottom'} ${
                                    isEntrySelected(entry) && 'selected'
                                }`}
                                onClick={entry.onClick ? entry.onClick : () => onMenuClicked(entry)}
                            >
                                <i className={`icon ${entry.icon}`}></i>
                                <div className="label">{entry.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Sidebar;
